/** @define headersbcg;  weak */
.headersbcg {
  position: relative;
}
.headersbcg--hp {
  height: 425px;
}
@media only screen and (min-width: 725px) {
  .headersbcg--hp {
    height: 710px;
  }
}
@media only screen and (max-width: 399px) {
  .headersbcg--hp {
    background: url("/images/headers/header_hp_400.webp") no-repeat top right;
  }
}
@media only screen and (min-width: 400px) and (max-width: 724px) {
  .headersbcg--hp {
    background: url("/images/headers/header_hp_800.webp") no-repeat top center;
  }
}
@media only screen and (min-width: 725px) and (max-width: 1319px) {
  .headersbcg--hp {
    background: url("/images/headers/header_hp_1320.webp") no-repeat top right;
  }
}
@media only screen and (min-width: 1320px) {
  .headersbcg--hp {
    background: url("/images/headers/header_hp_2560.webp") no-repeat top center;
  }
}
.headersbcg--contact {
  height: 375px;
}
@media only screen and (max-width: 399px) {
  .headersbcg--contact {
    background: url("/images/headers/header_sub_contact_400.webp") no-repeat top right;
  }
}
@media only screen and (min-width: 400px) and (max-width: 799px) {
  .headersbcg--contact {
    background: url("/images/headers/header_sub_contact_800.webp") no-repeat top right;
  }
}
@media only screen and (min-width: 800px) and (max-width: 1319px) {
  .headersbcg--contact {
    background: url("/images/headers/header_sub_contact_1320.webp") no-repeat top right;
  }
}
@media only screen and (min-width: 1320px) {
  .headersbcg--contact {
    background: url("/images/headers/header_sub_contact_2560.webp") no-repeat top center;
  }
}
.headersbcg--default {
  height: 375px;
}
@media only screen and (max-width: 399px) {
  .headersbcg--default {
    background: url("/images/headers/header_sub_default_400.webp") no-repeat top right;
  }
}
@media only screen and (min-width: 400px) and (max-width: 799px) {
  .headersbcg--default {
    background: url("/images/headers/header_sub_default_800.webp") no-repeat top right;
  }
}
@media only screen and (min-width: 800px) and (max-width: 1319px) {
  .headersbcg--default {
    background: url("/images/headers/header_sub_default_1320.webp") no-repeat top right;
  }
}
@media only screen and (min-width: 1320px) {
  .headersbcg--default {
    background: url("/images/headers/header_sub_default_2560.webp") no-repeat top center;
  }
}
.headersbcg--hp .headersbcg__text {
  padding: 110px 30px 0 30px;
}
@media only screen and (min-width: 1000px) {
  .headersbcg--hp .headersbcg__text {
    padding: 110px 450px 0 30px;
  }
}
@media only screen and (min-width: 1200px) {
  .headersbcg--hp .headersbcg__text {
    padding: 110px 700px 0 80px;
  }
}
.headersbcg--sub .headersbcg__text {
  padding: 90px 30px 0 30px;
}
@media only screen and (min-width: 1000px) {
  .headersbcg--sub .headersbcg__text {
    padding: 90px 450px 0 30px;
  }
}
@media only screen and (min-width: 1200px) {
  .headersbcg--sub .headersbcg__text {
    padding: 90px 700px 0 80px;
  }
}
.headersbcg__text {
  color: #ffffff;
}
.headersbcg__text .title {
  font-size: 18px;
}
@media only screen and (min-width: 600px) {
  .headersbcg__text .title {
    font-size: 22px;
  }
}
@media only screen and (min-width: 725px) {
  .headersbcg__text .title {
    font-size: 30px;
    line-height: 1.3;
  }
}
@media only screen and (min-width: 1000px) {
  .headersbcg__text .title {
    font-size: 50px;
  }
}
.headersbcg__text .subtitle {
  font-size: 16px;
}
@media only screen and (min-width: 600px) {
  .headersbcg__text .subtitle {
    font-size: 18px;
  }
}
@media only screen and (min-width: 600px) {
  .headersbcg__text .subtitle {
    font-size: 26px;
    line-height: 1.3;
  }
}
.no-webp {
  /* postcss-bem-linter: ignore */
  /* postcss-bem-linter: ignore */
}
@media only screen and (max-width: 399px) {
  .no-webp .headersbcg--hp {
    background: url("/images/headers/header_hp_400.jpg") no-repeat top right;
  }
}
@media only screen and (min-width: 400px) and (max-width: 724px) {
  .no-webp .headersbcg--hp {
    background: url("/images/headers/header_hp_800.jpg") no-repeat top center;
  }
}
@media only screen and (min-width: 725px) and (max-width: 1319px) {
  .no-webp .headersbcg--hp {
    background: url("/images/headers/header_hp_1320.jpg") no-repeat top right;
  }
}
@media only screen and (min-width: 1320px) {
  .no-webp .headersbcg--hp {
    background: url("/images/headers/header_hp_2560.jpg") no-repeat top center;
  }
}
.no-webp .headersbcg--contact {
  height: 375px;
}
@media only screen and (max-width: 399px) {
  .no-webp .headersbcg--contact {
    background: url("/images/headers/header_sub_contact_400.jpg") no-repeat top right;
  }
}
@media only screen and (min-width: 400px) and (max-width: 799px) {
  .no-webp .headersbcg--contact {
    background: url("/images/headers/header_sub_contact_800.jpg") no-repeat top right;
  }
}
@media only screen and (min-width: 800px) and (max-width: 1319px) {
  .no-webp .headersbcg--contact {
    background: url("/images/headers/header_sub_contact_1320.jpg") no-repeat top right;
  }
}
@media only screen and (min-width: 1320px) {
  .no-webp .headersbcg--contact {
    background: url("/images/headers/header_sub_contact_2560.jpg") no-repeat top center;
  }
}
/*# sourceMappingURL=css/headersbcg.css.map */